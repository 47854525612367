import React, { useCallback, useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import DocumentThumbnail from '../../DocumentVault/DocumentThumbnail'
import Skeleton from '../../../atoms/Skeleton'
import { getDocument } from '../../../../service'
import { useAppContext } from '../../../../redux/slices/appContext'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const useDocument = (documentId) => {
  const appContext = useAppContext()
  const [loading, setLoading] = useState(false)
  const [vaultDocument, setVaultDocument] = useState(null)
  const loadDocument = useCallback(
    async ({ documentId, clientId }) => {
      try {
        setLoading(true)
        const doc = await getDocument(clientId, documentId, false)
        setVaultDocument(doc?.data)
      } catch (err) {
        console.error(
          `There was a problem loading a document ${documentId}: `,
          err
        )
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setVaultDocument]
  )

  const download = useCallback(() => {
    if (!vaultDocument?.downloadUrl) {
      return
    }

    const link = document.createElement('a')
    link.href = vaultDocument.downloadUrl
    link.download = vaultDocument.name
    document.body.appendChild(link)
    link.click()
    link.remove()
  }, [vaultDocument])

  useEffect(() => {
    loadDocument({ documentId, clientId: appContext.clientId })
  }, [documentId, appContext.clientId, loadDocument])

  return {
    loading,
    download,
    document: vaultDocument
  }
}

const AssetTearDocument = ({ document, isLoading, ...props }) => {
  const classes = useStyles()

  const { download } = useDocument(document.id)

  if (isLoading) {
    return <Skeleton height='15rem' width='13.5rem' />
  }
  return (
    <Box className={classes.container} {...props}>
      <DocumentThumbnail
        key={document.documentId}
        onClick={download}
        title={document.name}
        src={document?.thumbnailSrc}
        updatedAt={document?.updatedAt}
        fallbackSrc={document?.defaultThumbnailSrc}
      />
    </Box>
  )
}
AssetTearDocument.propTypes = {
  document: PropTypes.object,
  isLoading: PropTypes.bool
}

AssetTearDocument.defaultProps = {
  document: {},
  isLoading: false
}

export default AssetTearDocument
