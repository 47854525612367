import React, { useMemo } from 'react'
import SydTabs from '../../commonDesign/SydTabs'
import { useVaultContext } from './VaultContextProvider'
import VaultCollection from './VaultCollection'

const DocumentVaultContent = () => {
  const { view, levels, labels, currentTab, setCurrentTab, canUpload, showUploadButton } = useVaultContext()

  const tabs = useMemo(() => levels?.reduce((acc, level, i) => {
    acc[level.levelTypeId] = {
      label: labels?.titles[level.levelTypeId],
      order: i
    }
    return acc
  }, {}), [labels?.titles, levels])

  const activeLevel = useMemo(() => {
    if (!currentTab) return null
    return levels.filter((level) => {
      return currentTab.toString() === level.levelTypeId.toString()
    })
  }, [currentTab, levels])

  return (
    <SydTabs.Group
      tabOptions={tabs}
      onChange={setCurrentTab}
      defaultValue={currentTab}
    >
      <SydTabs.Links />
      {currentTab && (
        <VaultCollection
          key={currentTab}
          levels={activeLevel}
          view={view}
          allowDetails
          canUpload={canUpload}
          showUploadButton={canUpload && showUploadButton}
        />
      )}
    </SydTabs.Group>
  )
}

export default DocumentVaultContent
