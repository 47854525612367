import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import SydModal from '../../../commonDesign/SydModal'
import SydLabel, { hookFormErrorAdapter } from '../../../commonDesign/SydLabel'
import SydInput from '../../../commonDesign/SydInput'
import VaultUploadsVisibilityToggle from '../VaultFileUpload/VaultUploadsVisibilityToggle'
import SydAutocomplete from '../../../commonDesign/SydAutocomplete'
import { useEditDocumentMutation, useListTags } from '../../../../api/documentVault'
import SydModalActions from '../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../commonDesign/Button'
import { getFileExtension } from '../../../../utils'

const FileEditModal = ({
  document,
  onClose,
  onFinish
}) => {
  const originalVisibility = document?.document?.visibility
  const { name, extension } = getFileExtension(document?.document?.name)
  const form = useForm({
    defaultValues: {
      name: name,
      description: document?.document?.description,
      tags: document?.document?.tags?.map(t => ({ value: t.tagId ?? t.id, label: t.name })) ?? [],
      visibility: document.document.visibility
    }
  })
  const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false)
  const [showUserConfirmation, setShowUserConfirmation] = React.useState(false)
  const visibility = form.watch('visibility')
  useEffect(() => {
    if (originalVisibility !== 'visible' && visibility === 'visible') {
      setIsConfirmationNeeded(true)
    } else {
      setIsConfirmationNeeded(false)
    }
  }, [originalVisibility, visibility])

  const { data: tags, isFetching: isLoadingTags } = useListTags({}, {
    mapper: (data) => {
      return data.tags?.map(x => ({
        value: x.id,
        label: x.name
      }))
    }
  })

  const { mutateAsync: saveSubmit, isLoading: isLoadingSubmit } = useEditDocumentMutation()
  const onSubmit = async (data) => {
    if (isConfirmationNeeded && !showUserConfirmation) {
      setShowUserConfirmation(true)
      return
    }
    await saveSubmit({
      document: {
        documentId: document.document.documentId,
        name: `${data.name}.${extension}`,
        description: data.description,
        tags: data.tags.map(t => ({ id: t.value })),
        visibility: data.visibility
      }
    }).then(() => {
      onFinish()
    }).catch(err => {
      form.setError('name', { message: err.message })
    })
  }

  if (!document) return null

  return (
    <>
      <form {...form} onSubmit={form.handleSubmit(onSubmit)}>
        <SydModal
          open={!!document}
          onClose={onClose}
          title='Edit Document'
          maxWidth='md'
        >
          <Box display='flex' flexDirection='column' gridGap={8}>
            <Controller
              control={form.control}
              name='name'
              rules={{ required: { value: true, message: 'Required' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Name'
                  required
                  description='Required'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Name...' {...field} />
                </SydLabel>
              )}
            />

            <Controller
              control={form.control}
              name='description'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Description'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Description...' {...field} />
                </SydLabel>
              )}
            />

            <Controller
              control={form.control}
              name='tags'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Tags'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydAutocomplete
                    size='sm'
                    multiple
                    defaultValue={field.value}
                    {...field.value}
                    onChange={(e, val) => form.setValue('tags', val)}
                    options={tags}
                    placeholder='Add tag'
                    isLoading={isLoadingTags}
                  />
                </SydLabel>
              )}
            />

            <SydLabel label='Visibility'>
              <VaultUploadsVisibilityToggle
                defaultVisibility={document.document.visibility}
                onToggleVisibility={(val) => form.setValue('visibility', val)}
              />
            </SydLabel>

            <SydModalActions>
              <Box display='flex' width='100%' justifyContent='space-between'>
                <SydButton onClick={onClose} disabled={isLoadingSubmit}>Cancel</SydButton>
                <SydButton type='submit' variant='primary' disabled={isLoadingSubmit}>{isLoadingSubmit ? 'Saving' : 'Save Changes'}</SydButton>
              </Box>
            </SydModalActions>
          </Box>
        </SydModal>

        <SydModal
          open={showUserConfirmation}
          onClose={onClose}
          title='Are you sure?'
          subtitle='You are about to make this document public for any users that can view this group'
          maxWidth='md'
        >
          <SydModalActions>
            <Box display='flex' width='100%' justifyContent='space-between'>
              <SydButton
                onClick={() => {
                  setShowUserConfirmation(false)
                }}
                disabled={isLoadingSubmit}
              >Cancel
              </SydButton>
              <SydButton type='submit' variant='primary' disabled={isLoadingSubmit}>{isLoadingSubmit ? 'Saving' : 'Save Changes'}</SydButton>
            </Box>
          </SydModalActions>
        </SydModal>
      </form>
    </>
  )
}

FileEditModal.propTypes = {
  document: PropTypes.object,
  onClose: PropTypes.func,
  onFinish: PropTypes.func
}

export default FileEditModal
