import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import isEmpty from 'lodash.isempty'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../constants'
import Text from '../../atoms/Text'
import Icon from '../../atoms/Icon'
import { useFallbackImg } from '../../../hooks'
import Menu from '../../molecules/Menu'
import Tag from '../../atoms/Tag'
import { usePublicImageUrl } from '../../../hooks/usePublicResource'

dayjs.extend(relativeTime)

const LABEL_HEIGHT = '69px'
const LABEL_POSITION = -20
const LABEL_HOVERED_POSITION = 0

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '1rem',
    border: '2px solid transparent',
    transition: 'border-color 0.3s',
    '&.active': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.main,
      '& $label': {
        bottom: LABEL_HOVERED_POSITION
      },
      '& $hoverDetails': {
        opacity: 1
      },
      '& $menuContainer': {
        opacity: 1
      }
    }
  },
  imageContainer: {
    borderRadius: 'inherit',
    backgroundColor: '#fafafa',
    aspectRatio: '.73',
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    width: '100%',
    maxHeight: '100%',
    borderRadius: 'inherit',
    padding: '12px',
    display: 'block'
  },
  label: ({ hasAdditionalDetails }) => ({
    height: LABEL_HEIGHT,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    bottom: hasAdditionalDetails ? LABEL_POSITION : 0,
    padding: '8px 16px',
    transition: 'bottom 0.3s'
  }),
  hoverDetails: {
    opacity: 0,
    transition: 'opacity 0.3s'
  },
  menuContainer: {
    opacity: 0,
    transition: 'opacity 0.3s'
  },
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  iconButton: {
    cursor: 'pointer'
  },
  tagContainer: {
    position: 'absolute',
    bottom: '100%',
    left: '6px',
    right: '6px',
    margin: '0 0 6px',
    display: 'flex',
    gridGap: '4px',
    flexWrap: 'wrap'
  },
  timestamp: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const DocumentThumbnail = ({
  onClick = noop,
  src = '',
  fallbackSrc: _fallbackSrc = null,
  document,
  menuOptions,
  includeDetails = true,
  includeTags = true
}) => {
  const [isActive, setIsActive] = useState(false)
  const hasAdditionalDetails = useMemo(() => !isEmpty(document.updatedDate), [document.updatedDate])
  const classes = useStyles({ hasAdditionalDetails })
  const theme = useTheme()
  const defaultFallbackSrc = usePublicImageUrl('firm_logo.png')
  const fallbackSrc = _fallbackSrc ?? defaultFallbackSrc
  const { src: imgSrc, onError } = useFallbackImg(src, fallbackSrc)

  const renderMenuIcon = useCallback(({ setAnchorEl }) => {
    return (
      <div
        className={classes.iconButton} onClick={setAnchorEl}
      >
        <Icon name={ICON_NAMES.threeDots} customSize='1.5rem' color='white' />
      </div>
    )
  }, [classes.iconButton])

  return (
    <div
      className={clsx(classes.container, { active: isActive })}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div
        className={classes.imageContainer}
        onClick={onClick}
      >
        <img
          src={imgSrc}
          onError={onError}
          className={classes.image}
          alt='File thumbnail'
          loading='lazy'
        />
      </div>
      {includeDetails && (
        <div
          className={classes.label}
        >
          {includeTags && (
            <div className={classes.tagContainer} onClick={onClick}>
              {document.tags.map(tag => (
                <Tag
                  fontSize='12px'
                  key={`${document.documentId}_${tag.tagId}`}
                  label={tag.name}
                  backgroundColor={tag.colorField ?? theme.palette.primary.main}
                  color={theme.palette.getContrastText(tag.colorField ?? theme.palette.primary?.main)}
                />
              ))}
            </div>
          )}
          <Box flex='1 1 auto' width='calc(100% - 1.5rem)' gridGap='0.25rem' onClick={onClick}>
            <div>
              <Text
                text={document.name}
                customFontSize='1rem'
                variant={TEXT_VARIANTS.h3}
                className={classes.title}
              />
            </div>
            {document.updatedDate && (
              <div className={classes.hoverDetails}>
                <Text
                  text={`Last updated ${dayjs(document.updatedDate).fromNow()}`}
                  customFontSize='0.75rem'
                  className={classes.timestamp}
                />
              </div>
            )}
          </Box>
          {!isEmpty(menuOptions) && (
            <div className={classes.menuContainer}>
              <Menu options={menuOptions}>{renderMenuIcon}</Menu>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

DocumentThumbnail.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  document: PropTypes.shape({
    documentId: PropTypes.number,
    name: PropTypes.string,
    updatedDate: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      colorField: PropTypes.string
    }))
  }),
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  })),
  fallbackSrc: PropTypes.string,
  includeDetails: PropTypes.bool,
  includeTags: PropTypes.bool
}

export default DocumentThumbnail
