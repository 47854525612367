import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import VaultContextProvider from './VaultContextProvider'
import { DefaultVaultContent } from './DefaultVaultContent'
import DocumentDetail from './DocumentDetail'

const VaultWrapper = ({
  levels,
  labels,
  availableViews,
  children: _children,
  uploadLevelTypeIds,
  showUploadButton = true
}) => {
  const { search } = useLocation()
  const children = useMemo(() => {
    if (!_children) return <DefaultVaultContent />
    return _children
  }, [_children])

  const currentDocumentId = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get('documentId') ?? null
  }, [search])

  return (
    <Box padding='20px' display='flex' flexDirection='column' height='100%'>
      <VaultContextProvider
        levels={levels}
        labels={labels}
        uploadLevelTypeIds={uploadLevelTypeIds}
        showUploadButton={showUploadButton}
        availableViews={availableViews}
      >
        {currentDocumentId
          ? <DocumentDetail documentId={currentDocumentId} />
          : children}
      </VaultContextProvider>
    </Box>
  )
}

VaultWrapper.propTypes = {
  levels: PropTypes.array,
  labels: PropTypes.object,
  children: PropTypes.node,
  uploadLevelTypeIds: PropTypes.array,
  showUploadButton: PropTypes.bool,
  availableViews: PropTypes.array
}

export default VaultWrapper
