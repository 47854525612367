import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'
import { ICON_NAMES } from '../../../../constants'
import Text from '../../../atoms/Text'
import { useAppContext } from '../../../../redux/slices/appContext'

const visibilityOptions = [
  {
    icon: ICON_NAMES.suitcase,
    value: 'internal',
    label: 'Internal',
    description: 'Will only be visible to other advisors'
  },
  {
    icon: ICON_NAMES.user,
    value: 'hidden',
    label: 'Private',
    description: 'Will only be visible to you'
  },
  {
    icon: ICON_NAMES.visibility,
    value: 'visible',
    label: 'Public',
    description: 'Will be visible to all users with access to this group'
  }
]

const useVisibilityToggleStyles = makeStyles((theme) => ({
  visibilityButtonsContainer: {
    display: 'flex',
    gridGap: '12px',
    flexWrap: 'wrap'
  },
  visibilityButton: {
    display: 'flex',
    flex: '1 1 150px',
    gap: '12px',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  visibilityButtonContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '2px'
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

const VaultUploadsVisibilityToggle = ({
  defaultVisibility = 'internal',
  onToggleVisibility = noop
}) => {
  const { isAdvisor } = useAppContext()
  const classes = useVisibilityToggleStyles()
  const [visibility, setVisibility] = useState(defaultVisibility)

  const availableVisibilityOptions = useMemo(() => (
    visibilityOptions.filter((option) => isAdvisor || option.value !== 'internal')
  ), [isAdvisor])

  const onToggleVisibilityHandler = (val) => {
    setVisibility(val)
    onToggleVisibility(val)
  }

  return (
    <div className={classes.visibilityButtonsContainer}>
      {availableVisibilityOptions.map((option) => (
        <button
          type='button'
          key={option.value}
          onClick={() => onToggleVisibilityHandler(option.value)}
          className={clsx({
            [classes.visibilityButton]: true,
            [classes.activeButton]: visibility === option.value
          })}
        >
          <Icon name={option.icon} customSize='20px' />
          <div className={classes.visibilityButtonContent}>
            <Text variant='h4' text={option.label} />
            <Text variant='body2' text={option.description} />
          </div>
        </button>
      ))}
    </div>
  )
}

VaultUploadsVisibilityToggle.propTypes = {
  defaultVisibility: PropTypes.string,
  onToggleVisibility: PropTypes.func
}

export default VaultUploadsVisibilityToggle
