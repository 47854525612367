import PropTypes from 'prop-types'
import { alpha, makeStyles, Popover } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  iconsContainer: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    display: 'flex',
    zIndex: 100
  },
  iconElement: {
    background: alpha(theme.palette.summitBlue, 0.8),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.25rem',
    height: '2.25rem'
  }
}))

const visibilityIcon = {
  hidden: 'user',
  internal: 'suitcase'
}
const visibilityLabels = {
  hidden: 'Private',
  internal: 'Internal'
}

const VisibilityIcon = ({ visibility, labels = visibilityLabels }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleMouseEnter = useCallback((e) => setAnchorEl(e.currentTarget), [setAnchorEl])
  const handleMouseLeave = useCallback((e) => setAnchorEl(null), [setAnchorEl])

  const iconName = visibilityIcon[visibility]
  const title = labels[visibility] ?? visibilityLabels[visibility]

  if (!iconName) return null

  return (
    <div className={classes.iconsContainer}>
      {title?.length && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          title={title}
        />
      )}
      <div
        className={classes.iconElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon
          name={iconName}
          customSize='18px'
          color={theme.palette.primary.contrastText}
        />
      </div>
    </div>
  )
}
VisibilityIcon.propTypes = {
  visibility: PropTypes.object,
  labels: PropTypes.object
}

export default VisibilityIcon
