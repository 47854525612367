import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import noop from 'lodash/noop'
import { TEXT_VARIANTS } from '../../../../constants'
import RoundedButton from '../../../atoms/RoundedButton'
import Text from '../../../atoms/Text'
import { fileRejections } from '../../../../prop-types'
import { useAppContext } from '../../../../redux/slices/appContext'
import DocumentVaultFileRejectionMessageList from '../../../molecules/DocumentVaultFileRejectionMessageList'
import CheckBoxItem from '../../../molecules/CheckBoxItem'
import { useEditDocumentsMutation, useListTags } from '../../../../api/documentVault'
import VaultUploadsPreviewCard from './VaultUploadsPreviewCard'
import { useFileEvents } from './hooks'
import { usePreviewContainerStyles } from './styles'

const VaultUploadsPreview = ({
  files,
  onFinish,
  levelId,
  levelTypeId,
  fileRejections,
  openUploadFileWindow,
  showPreviewPlaceHolder,
  showFileRejectionsMessage,
  onCancel
}) => {
  const classes = usePreviewContainerStyles()
  const [documents, setDocuments] = useState(files)
  const [notifyClient, setNotifyClient] = useState(false)
  const {
    onDeleteFile,
    setFiles,
    deleteDocumentMutation: {
      isLoading: isDeleteLoading
    }
  } = useFileEvents(levelTypeId, levelId)

  const appContext = useAppContext()

  const updateDocument = useCallback((document) => {
    setDocuments((prevDocuments) => {
      const index = prevDocuments.findIndex((prevDocument) => prevDocument?.document?.documentId && prevDocument?.document?.documentId === document?.document?.documentId
      )
      return [...prevDocuments.slice(0, index), document, ...prevDocuments.slice(index + 1)]
    })
  }, [])

  const documentsFormatted = useMemo(() => {
    return documents.map((document) => ({
      ...document,
      shouldNotifyWealthOwner: notifyClient,
      levelId,
      levelTypeId
    }))
  }, [documents, levelId, levelTypeId, notifyClient])

  const { mutateAsync: updateDocumentAsync, isLoading: isLoadingSubmit } = useEditDocumentsMutation()
  const onFinishHandler = useCallback(async () => {
    try {
      const { data: updatedDocuments } = await updateDocumentAsync({ documents: documentsFormatted })
      onFinish(updatedDocuments)
    } catch (err) {
      // TODO: Add error handling here
      console.error(err)
      onFinish([])
    }
  }, [updateDocumentAsync, documentsFormatted, onFinish])

  const { data: availableTags, isFetching: isLoadingTags } = useListTags({}, {
    mapper: (data) => {
      return data.tags?.map(x => ({
        value: x.id,
        label: x.name
      }))
    }
  })

  const onCheckChange = useCallback((e) => {
    setNotifyClient(e.target.checked)
  }, [setNotifyClient])

  const handleOnDelete = useCallback(async ({ documentId }) => {
    await onDeleteFile({ documentId })
    setDocuments((prevDocuments) => prevDocuments.filter((prevDocument) => prevDocument?.document?.documentId !== documentId))
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.document?.documentId !== documentId))
    if (!documents.filter((prevDocument) => prevDocument?.document?.documentId !== documentId)?.length) {
      onCancel()
    }
  }, [documents, onCancel, onDeleteFile, setFiles])

  return (
    <Box p={2} height='100%' maxWidth='800px' width='90%' margin='0 auto' minHeight='450px'>
      <Box mb={4}>
        <Text text='Files' variant={TEXT_VARIANTS.h1} customFontWeight='bold' />
      </Box>
      {showFileRejectionsMessage && (
        <div className={classes.cardFileRejectionMessagesContainer}>
          <DocumentVaultFileRejectionMessageList
            fileRejections={fileRejections}
          />
        </div>
      )}
      <div className={classes.cardsContainer}>
        {documents.map((document, index) => {
          return (
            <Box key={`${index}`}>
              <VaultUploadsPreviewCard
                tags={availableTags}
                document={document}
                onUpdateDocument={updateDocument}
                onDeleteFile={() => handleOnDelete({ documentId: document.document.documentId })}
                showLoadingPlaceHolder={showPreviewPlaceHolder || isLoadingTags || isDeleteLoading}
              />
            </Box>
          )
        })}
      </div>
      {appContext.isAdvisor && (
        <Box mt={4}>
          <CheckBoxItem checked={notifyClient} onChange={onCheckChange}>Notify client of new document upload</CheckBoxItem>
        </Box>
      )}
      <Box mt={4}>
        <div className={classes.buttonsSection}>
          <RoundedButton
            variant='outlined'
            fullWidth
            onClick={openUploadFileWindow}
          >
            Upload more files
          </RoundedButton>
          <RoundedButton
            primary
            fullWidth
            disabled={isLoadingSubmit}
            onClick={onFinishHandler}
          >
            Finish
            {isLoadingSubmit && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
          </RoundedButton>
        </div>
      </Box>
    </Box>
  )
}

VaultUploadsPreview.propTypes = {
  levelId: PropTypes.number,
  levelTypeId: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.object),
  fileRejections,
  openUploadFileWindow: PropTypes.func,
  showPreviewPlaceHolder: PropTypes.bool,
  showFileRejectionsMessage: PropTypes.bool,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func
}

VaultUploadsPreview.defaultProps = {
  levelId: undefined,
  levelTypeId: 201,
  files: [],
  fileRejections: [],
  onDeleteFile: noop,
  onFinish: noop,
  openUploadFileWindow: noop,
  showPreviewPlaceHolder: false,
  showFileRejectionsMessage: false,
  onCancel: noop
}

export default VaultUploadsPreview
