import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useVaultContext } from '../VaultContextProvider'
import { useNormalizeLevelValues } from '../hooks'
import { useStyles } from './styles'
import { getColumnConfig } from './columnConfig'
import VaultCollectionContent from './VaultCollectionContent'
import VaultCardGrid from './VaultCardGrid'
import VaultList from './VaultList'

const defaultLabels = {
  emptyState: {
    icon: 'paperMilestones',
    title: 'No documents found',
    subtitle: 'No documents exist yet.  Drag and drop documents here to add them'
  }
}

const VaultCollection = ({
  title,
  levels: _levels,
  view: defaultView = 'grid',
  allowDetails = false,
  labels: _labels,
  skip = 0,
  take = 100,
  orderBy,
  filters: _filters = {},
  columns: _columns = ['name', 'download', 'edit'],
  showTags = true,
  canUpload = false,
  showUploadButton = true
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const queryClient = useQueryClient()
  const {
    filters,
    view: contextView,
    levels: contextLevels,
    labels: contextLabels,
    showUploadOverlay
  } = useVaultContext()
  const view = useMemo(() => contextView ?? defaultView, [contextView, defaultView])

  const levels = useNormalizeLevelValues(_levels ?? contextLevels)

  const contextFilterTagIds = useMemo(() => {
    return filters.tags?.map(tag => tag.value)
  }, [filters.tags])

  const queryParams = useMemo(() => {
    const textSearch = filters.textSearch?.length
      ? { name: [{ op: 'contains', value: filters.textSearch }] } : {}

    const tagIdsFilter = [
      ..._filters.tagIds ?? [],
      ...contextFilterTagIds?.length ? [{ op: 'in', value: contextFilterTagIds }] : []
    ]

    return {
      filters: {
        ..._filters,
        levels,
        tagIds: tagIdsFilter?.length ? tagIdsFilter : undefined,
        status: [{ op: 'eq', value: 'uploaded' }]
      },
      textSearch,
      includes: {
        tags: true,
        createdByUser: true
      },
      includeThumbnailUrl: true,
      take,
      skip,
      orderBy
    }
  }, [filters.textSearch, _filters, levels, contextFilterTagIds, take, skip, orderBy])

  const columns = useMemo(() => getColumnConfig(_columns), [_columns])

  const handleDocumentClick = useCallback((document) => {
    if (!allowDetails) return
    const searchParams = new URLSearchParams(search)
    searchParams.set('documentId', document.documentId)
    history.push({ search: searchParams.toString() })
  }, [allowDetails, history, search])

  const refetch = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['list-documents'] })
  }, [queryClient])

  return (
    <div className={classes.container}>
      {title?.length && (<div className={classes.sectionTitle}>{title}</div>)}
      {levels.map(({ levelTypeId, levelIds }) => (
        <VaultCollectionContent
          key={levelTypeId}
          canUpload={canUpload}
          levelId={levelIds?.[0]}
          levelTypeId={levelTypeId}
          onRefetch={() => refetch()}
          showUploadOverlay={showUploadOverlay}
          showUploadButton={showUploadButton}
          labels={_labels ?? contextLabels ?? defaultLabels}
        >
          {['grid', 'grid_horizontal'].includes(view) && (
            <VaultCardGrid
              labels={_labels ?? contextLabels ?? defaultLabels}
              baseQueryParams={queryParams}
              showTags={showTags}
              view={view}
              onClick={handleDocumentClick}
              canUpload={canUpload}
            />
          )}

          {view === 'list' && (
            <VaultList
              labels={_labels ?? contextLabels ?? defaultLabels}
              columns={columns}
              baseQueryParams={queryParams}
              showTags={showTags}
              onClick={handleDocumentClick}
              canUpload={canUpload}

            />
          )}
        </VaultCollectionContent>
      ))}
    </div>
  )
}

VaultCollection.propTypes = {
  title: PropTypes.string,
  levels: PropTypes.arrayOf(PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelIds: PropTypes.arrayOf(PropTypes.number)
  })),
  allowDetails: PropTypes.bool,
  showTags: PropTypes.bool,
  labels: PropTypes.object,
  view: PropTypes.oneOf(['grid', 'list', 'grid_horizontal']),
  filters: PropTypes.object,
  take: PropTypes.number,
  skip: PropTypes.number,
  orderBy: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    dir: PropTypes.oneOf(['asc', 'desc'])
  })),
  columns: PropTypes.arrayOf(PropTypes.string),
  canUpload: PropTypes.bool,
  showUploadButton: PropTypes.bool
}

export default VaultCollection
