import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    flex: 1
  },
  gridContainer: {
    display: 'flex',
    gridGap: '20px',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '10px 0 30px',
    '&.horizontalScroll': {
      flexWrap: 'nowrap',
      overflowX: 'auto'
    }
  },
  sectionTitle: {
    fontWeight: 600,
    padding: '2px 0 !important',
    fontSize: '1.65rem',
    textTransform: 'capitalize'
  },
  tableContainer: {
    width: '100%'
  }
}))
