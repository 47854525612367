import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { useTheme } from '@material-ui/styles'
import { getFileExtension } from '../../../../utils'
import InputInlineEdit from '../../../molecules/InputInlineEdit'
import SydAutocomplete from '../../../commonDesign/SydAutocomplete'
import { uploadDocument } from '../../../../service'
import { useEditDocumentMutation } from '../../../../api/documentVault'
import { TEXT_VARIANTS } from '../../../../constants'
import Text from '../../../atoms/Text'
import VaultUploadsVisibilityToggle from './VaultUploadsVisibilityToggle'
import { usePreviewCardStyles } from './styles'

const VaultUploadsPreviewCard = ({
  showPlaceHolder,
  showLoadingPlaceHolder,
  onDeleteFile,
  onUpdateDocument,
  document,
  tags,
  showVisibilityToggle
}) => {
  const theme = useTheme()
  const documentDetails = document?.document ?? {}
  const [errors, setErrors] = useState({})
  const [documentTitle, setDocumentTitle] = useState(getFileExtension(documentDetails?.name, true))
  const [documentUpload, setDocumentUpload] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })
  const [documentUploadRequest, setDocumentUploadRequest] = useState(null)

  const classes = usePreviewCardStyles({
    showPlaceHolder,
    progress: documentUpload.progress,
    isDocumentUploaded: documentUpload.uploaded
  })

  useEffect(() => {
    if (showPlaceHolder || !document) return
    const isDocumentUploadCompleted = documentUpload.progress === 100
    const isDocumentUploadFailed =
      documentUpload.aborted || documentUpload.hasError
    if (
      (isDocumentUploadCompleted && document.document.status !== 'uploaded') ||
      (isDocumentUploadFailed && document.document.status !== 'failed')
    ) {
      onUpdateDocument({
        ...document,
        document: {
          ...document.document,
          status: isDocumentUploadCompleted ? 'uploaded' : isDocumentUploadFailed ? 'failed' : 'pending'
        }
      })
    }
  }, [
    showPlaceHolder,
    onUpdateDocument,
    documentUpload.hasError,
    documentUpload.progress,
    documentUpload.aborted,
    document
  ])

  useEffect(() => {
    if (showPlaceHolder) return
    const xhr = new XMLHttpRequest()
    setDocumentUploadRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [showPlaceHolder])

  const onUploadFileProgress = useCallback(
    (event) =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onAbortDocumentUpload = useCallback(
    () =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const uploadDocumentFile = useCallback(
    async (file) => {
      if (!document || !documentUploadRequest) return
      const { url: uploadUrl } = document
      try {
        await uploadDocument(
          uploadUrl,
          file,
          onUploadFileProgress,
          onAbortDocumentUpload,
          documentUploadRequest
        )
        setDocumentUpload((prevState) => ({
          ...prevState,
          uploaded: true
        }))
      } catch (error) {
        setDocumentUpload({
          progress: 0,
          uploaded: false,
          hasError: true
        })
        console.error('There was an error trying to upload the file', error)
      } finally {
        setDocumentUploadRequest(null)
      }
    },
    [onUploadFileProgress, onAbortDocumentUpload, documentUploadRequest, document]
  )

  const onChangeDocumentTitleHandler = useCallback(
    (e) => setDocumentTitle(({ name: e.target.value, extension: documentTitle.extension })),
    [documentTitle.extension]
  )

  const { mutateAsync: editDocumentAsync, isLoading: isLoadingEdit } = useEditDocumentMutation()
  const onEditDocumentTitleHandler = useCallback(
    async (title) => {
      setErrors({})
      if (!document) return
      const updatedDoc = {
        ...document,
        name: `${title.trim()}.${documentTitle.extension}`,
        document: {
          ...document.document,
          name: `${title.trim()}.${documentTitle.extension}`
        }
      }
      try {
        const { document } = await editDocumentAsync({
          document: {
            documentId: updatedDoc.document.documentId,
            name: updatedDoc.document.name
          }
        })
        onUpdateDocument(document)
      } catch (err) {
        setErrors((prevState) => ({ ...prevState, title: err?.message ?? 'There was an error saving this name.  Try changing it or saving again' }))
      }
    }, [document, documentTitle.extension, editDocumentAsync, onUpdateDocument]
  )

  const onEditTagsHandler = useCallback(
    (e, _tags) => {
      if (!document) return
      const tags = _tags?.map((tag) => ({
        id: tag.value,
        name: tag.label
      }))
      onUpdateDocument({ ...document, tags: tags })
    },
    [document, onUpdateDocument]
  )

  useEffect(() => {
    if (document.document.status !== 'pending') return
    uploadDocumentFile(document.file).then()
  }, [document, uploadDocumentFile])

  const renderDocumentUploadStatusIcon = useMemo(() => {
    if (documentUpload.hasError || documentUpload.aborted) {
      return <CloseIcon className={classes.errorIcon} />
    }
    if (documentUpload.uploaded) {
      return <CheckIcon className={classes.checkIcon} />
    }
    return null
  }, [documentUpload, classes.checkIcon, classes.errorIcon])

  if (showLoadingPlaceHolder || isLoadingEdit) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardLoadingPlaceHolder)}>
          <CircularProgress color='primary' />
        </div>
      </div>
    )
  }

  if (showPlaceHolder) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardPlaceHolder)}>
          <DescriptionOutlinedIcon />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DescriptionOutlinedIcon />
        <Box flex={1}>
          <InputInlineEdit
            showEditIcon
            icon={DoneIcon}
            value={documentTitle.name}
            placeHolderValue={`${documentTitle.name}.${documentTitle.extension}`}
            width='100%'
            onIconClickHandler={onEditDocumentTitleHandler}
            onChangeHandler={onChangeDocumentTitleHandler}
            error={errors.title}
            onCancelIconClickHandler={() => {
              setDocumentTitle(getFileExtension(documentDetails.name, true))
              setErrors({})
            }}
            useErrorToValidate
            initInEditMode={!!errors.title}
          />
          {errors.title && <Text variant={TEXT_VARIANTS.caption} color={theme.palette.error.main} text={errors.title} />}
        </Box>
        {renderDocumentUploadStatusIcon}
        {documentUploadRequest && !documentUpload.aborted ? (
          <CloseIcon
            className={classes.icon}
            onClick={(e) => documentUploadRequest.abort()}
          />
        ) : (
          <DeleteOutlineIcon className={classes.icon} onClick={onDeleteFile} />
        )}
      </div>
      <div className={classes.row}>
        <LabelOutlinedIcon hidden />
        <SydAutocomplete
          size='sm'
          multiple
          options={tags}
          onChange={onEditTagsHandler}
          getOptionSelected={(option, value) => document.tags.map(x => x.id).includes(option.value)}
          selectedTags={document.tags ?? []}
          placeholder='Add tag'
        />
      </div>
      <div className={classes.row}>
        <VisibilityOutlinedIcon hidden />
        {showVisibilityToggle && (
          <Box mt='8px'>
            <VaultUploadsVisibilityToggle
              defaultVisibility={document.document.visibility}
              onToggleVisibility={(visibility) =>
                onUpdateDocument({
                  ...document,
                  document: {
                    ...document.document,
                    visibility
                  }
                })}
            />
          </Box>
        )}
      </div>
    </div>
  )
}

VaultUploadsPreviewCard.propTypes = {
  document: {
    document: {
      documentId: PropTypes.string,
      name: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.number)
    },
    url: PropTypes.string
  },
  showPlaceHolder: PropTypes.bool,
  showLoadingPlaceHolder: PropTypes.bool,
  onDeleteFile: PropTypes.func,
  onUpdateDocument: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object),
  showVisibilityToggle: PropTypes.bool
}

VaultUploadsPreviewCard.defaultProps = {
  document: undefined,
  showPlaceHolder: false,
  showLoadingPlaceHolder: false,
  onDeleteFile: noop,
  onUpdateDocument: noop,
  documentSpecification: null,
  showVisibilityToggle: true
}

export default VaultUploadsPreviewCard
