import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useGetDocument } from '../../../../api/documentVault'
import DocumentThumbnail from '../DocumentThumbnail'
import { ICON_NAMES } from '../../../../constants'
import { postNamedQuery } from '../../../../service'
import Tag from '../../../atoms/Tag'
import SydButton from '../../../commonDesign/Button'
import Skeleton from '../../../atoms/Skeleton'
import { useVaultContext } from '../VaultContextProvider'
import SydModal from '../../../commonDesign/SydModal'
import { useFileEvents } from '../VaultFileUpload/hooks'
import Text from '../../../atoms/Text'
import { useInternalUserId } from '../../../../redux/slices/appContext'
import VisibilityIcon from '../VaultCard/VisibilityIcon'
import VaultEmptyState from '../VaultCollection/VaultEmptyState'
import FileEditModal from '../FileEditModal'
import { useStyles } from './styles'

const DocumentDetail = ({
  documentId
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { search } = useLocation()
  const history = useHistory()
  const internalUserId = useInternalUserId()
  const { detailItems, labels } = useVaultContext()
  const { data, isLoading, refetch } = useGetDocument({
    documentId,
    includeThumbnailUrl: true,
    includes: { tags: true, createdByUser: true }
  })
  const { document, thumbnailUrl } = useMemo(() => data ?? {}, [data])

  const [editingDocument, setEditingDocument] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)

  const handleDownloadDocument = useCallback(async () => {
    setLoadingDownload(true)
    const { data: docData } = await postNamedQuery('vaultV2', 'getDocument', { documentId, includeUrl: true })
    setLoadingDownload(false)
    window.open(docData.url, '_blank')
  }, [documentId])

  const handleBackButton = useCallback(() => {
    const searchParams = new URLSearchParams(search)
    searchParams.delete('documentId')
    history.push({ search: searchParams.toString() })
  }, [history, search])

  const userCanDelete = useMemo(() => {
    return document?.createdByUser?.userId === internalUserId
  }, [internalUserId, document])

  const userCanEdit = useMemo(() => {
    return document?.createdByUser?.userId === internalUserId
  }, [internalUserId, document])

  const { deleteDocumentMutation: { isLoading: isLoadingDelete }, onDeleteFile } = useFileEvents()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDeleteDocument = useCallback(async () => {
    await onDeleteFile(documentId)
    setIsDeleteModalOpen(false)
    handleBackButton()
  }, [documentId, handleBackButton, onDeleteFile])

  return (
    <div className={classes.container}>
      <div>
        <SydButton
          icon={ICON_NAMES.back}
          onClick={handleBackButton}
        >
          {labels?.documentDetails?.backButton}
        </SydButton>
      </div>

      {!document && !isLoading && (
        <VaultEmptyState
          icon={labels.emptyState.icon}
          title='Document not found'
          subtitle='The document you are looking for does not exist, has been deleted, or you do not have access to it'
        />
      )}

      <div className={classes.contentContainer}>
        <div className={classes.thumbnailContainer}>
          {isLoading && <Skeleton height='400px' />}
          {document && thumbnailUrl && (
            <DocumentThumbnail
              document={document}
              src={thumbnailUrl}
              menuOptions={[]}
              includeDetails={false}
              includeTags={false}
            />
          )}
          <VisibilityIcon visibility={document?.visibility} labels={labels.visibilityLabels} />
        </div>
        <div className={classes.detailsContainer}>
          {isLoading && (
            <>
              <div>
                <Skeleton height='50px' />
                {(detailItems.includes('downloadButton') || detailItems.includes('deleteButton')) && (
                  <div className={classes.actionsContainer} style={{ marginTop: '10px' }}>
                    {detailItems.includes('downloadButton') && <Skeleton height='30px' width='120px' />}
                    {detailItems.includes('deleteButton') && <Skeleton height='30px' width='155px' />}
                  </div>
                )}
              </div>
              {detailItems.includes('description') && <Skeleton height='80px' />}
              {detailItems.includes('tags') && (
                <div className={clsx([classes.detailBlockContent, classes.tagContent])}>
                  <Skeleton height='30px' width='150px' />
                  <Skeleton height='30px' width='150px' />
                  <Skeleton height='30px' width='150px' />
                </div>
              )}
            </>
          )}
          {document && (
            <>
              <div>
                <h3 className={classes.documentTitle}>{document.name}</h3>
                <div className={classes.actionsContainer}>
                  {detailItems.includes('downloadButton') && (
                    <SydButton
                      size='sm'
                      icon={ICON_NAMES.download}
                      onClick={handleDownloadDocument}
                      disabled={loadingDownload}
                    >
                      {loadingDownload ? 'Loading...' : 'Download'}
                    </SydButton>
                  )}
                  {detailItems.includes('deleteButton') && userCanDelete && (
                    <SydButton
                      size='sm'
                      icon={ICON_NAMES.delete}
                      onClick={() => setIsDeleteModalOpen(true)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? 'Deleting...' : 'Delete Document'}
                    </SydButton>
                  )}
                  {detailItems.includes('deleteButton') && userCanEdit && (
                    <SydButton
                      size='sm'
                      icon={ICON_NAMES.edit}
                      onClick={() => setEditingDocument(true)}
                      disabled={isLoadingDelete}
                    >
                      Edit Document
                    </SydButton>
                  )}
                </div>
              </div>
              {!!document.description?.length && detailItems.includes('description') && (
                <div className={classes.detailsBlock}>
                  <div className={classes.detailBlockTitle}>Description:</div>
                  <div className={classes.detailBlockContent}>{document.description}</div>
                </div>
              )}
              {!!document.tags?.length && detailItems.includes('tags') && (
                <div className={classes.detailsBlock}>
                  <div className={classes.detailBlockTitle}>Tags:</div>

                  <div className={clsx([classes.detailBlockContent, classes.tagContent])}>
                    {document.tags.map(tag => (
                      <Tag
                        key={tag}
                        label={tag.name}
                        backgroundColor={tag.colorField ?? theme.palette.primary.main}
                        color={theme.palette.getContrastText(tag.colorField ?? theme.palette.primary?.main)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {!!document.createdByUser && detailItems.includes('createdByUser') && (
                <div className={classes.detailsBlock}>
                  <div className={classes.detailBlockTitle}>Created By:</div>

                  <div className={classes.detailBlockContent}>
                    {document.createdByUser?.firstName} {document.createdByUser?.lastName}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <SydModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Are you sure?'
        subtitle={`Are you sure you want to delete ${document?.name}?`}
      >
        <Text variant='body1' text='This can not be undone' />

        <Box display='flex' justifyContent='space-between'>
          <SydButton
            priority='warning'
            onClick={handleDeleteDocument}
          >
            Delete
          </SydButton>
          <SydButton
            variant='secondary'
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </SydButton>
        </Box>
      </SydModal>

      {editingDocument && (
        <>
          <FileEditModal
            document={{ document }}
            onClose={() => setEditingDocument(false)}
            onFinish={async () => {
              await refetch()
              setEditingDocument(null)
            }}
          />
        </>
      )}
    </div>
  )
}

DocumentDetail.propTypes = {
  documentId: PropTypes.number.isRequired
}

export default DocumentDetail
