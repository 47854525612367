import { makeStyles } from '@material-ui/core'

export const usePreviewContainerStyles = makeStyles((theme) => ({
  buttonsSection: {
    display: 'flex',
    '& button:first-child': {
      marginRight: '0.5rem'
    },
    '& button:nth-child(2)': {
      marginLeft: '0.5rem'
    }
  },
  circularProgress: {
    position: 'absolute',
    right: '10%'
  },
  cardsContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100% - 14rem)'
  },
  cardFileRejectionMessagesContainer: {
    width: '35rem',
    marginBottom: '1rem',
    paddingRight: '1rem'
  },
  cardFileUploadPreviewsContainer: {
    width: '35rem',
    marginTop: '1rem',
    paddingRight: '1rem'
  }
}))

export const usePreviewCardStyles = makeStyles((theme) => ({
  container: ({ showPlaceHolder, progress, isDocumentUploaded }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.white,
    padding: '1rem',
    border: `1px solid ${theme.palette.gray.dark}`,
    width: '100%',
    minHeight: '6.625rem',
    height: 'auto',
    position: 'relative',
    '& svg': {
      fontSize: '1.5rem !important'
    },
    ...(showPlaceHolder
      ? {
        backgroundColor: theme.palette.gray.dark,
        minHeight: '3rem',
        '& svg': {
          fontSize: '1.5rem !important',
          opacity: '0.3'
        }
      }
      : {}),
    ...(progress !== 0 && !isDocumentUploaded
      ? {
        background: `linear-gradient(to right, ${theme.palette.ghost} ${progress}%, #ffffff 0%)`
      }
      : {})
  }),
  row: {
    display: 'flex',
    flex: '1 1 50%',
    alignItems: 'center',
    zIndex: 2,
    gridGap: '12px'
  },
  cardPlaceHolder: {
    alignItems: 'end'
  },
  cardLoadingPlaceHolder: {
    alignSelf: 'center'
  },
  cardTitle: {
    marginRight: 'auto'
  },
  checkIcon: {
    color: theme.palette.emerald,
    marginRight: '0.5rem'
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem'
  },
  icon: {
    marginLeft: 'auto',
    cursor: 'pointer'
  }
}))
