import DocumentDownloadCell from './Cells/DocumentDownloadCell'

const predefinedColumns = new Map([
  ['name', {
    id: 'name',
    accessor: 'name',
    key: 'name',
    Header: 'Name'
  }],
  ['download', {
    id: 'download',
    accessor: 'document.documentId',
    key: 'download',
    Header: 'Download',
    Cell: DocumentDownloadCell
  }],
  ['edit', {
    id: 'edit',
    accessor: 'document.documentId',
    key: 'edit',
    Header: 'Edit',
    Cell: () => 'Edit'
  }]
])

export const getColumnConfig = (columns) => {
  return columns.map(column => predefinedColumns.get(column))
}
