import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import PresentationTable from '../../PresentationTable'
import { useListDocumentsGroupedByTags } from '../../../../api/documentVault'
import { useStyles } from './styles'
import VaultEmptyState from './VaultEmptyState'

const VaultList = ({
  baseQueryParams,
  labels,
  onClick,
  columns,
  canUpload
}) => {
  const classes = useStyles()

  const queryParams = useMemo(() => baseQueryParams, [baseQueryParams])

  const { data, isFetching: isLoading } = useListDocumentsGroupedByTags(queryParams, {
    mapper: data => (data.tags ?? []).map(tag => ({
      ...tag,
      _next: true,
      subRows: tag.documents.map(d => ({ ...d, name: d.document.name, _next: null }))
    }))
  })

  return (
    <>
      <div className={classes.tableContainer}>
        {!isLoading && !data?.length && (
          <VaultEmptyState
            icon={labels.emptyState.icon}
            title={labels.emptyState.title}
            subtitle={labels.emptyState.subtitle}
            uploadStatement={canUpload ? labels.emptyState.uploadStatement : null}
          />
        )}
        {(isLoading || data?.length) && (
          <PresentationTable.Wrapper>
            <PresentationTable
              data={data ?? []}
              sortable
              loading={isLoading}
              columns={columns}
              expandable
              defaultExpanded
              onRowClick={(row) => {
                if (!row.original.document?.documentId) return
                onClick(row.original.document)
              }}
            />
          </PresentationTable.Wrapper>
        )}
      </div>
    </>
  )
}

VaultList.propTypes = {
  baseQueryParams: PropTypes.object,
  labels: PropTypes.object,
  onClick: PropTypes.func,
  columns: PropTypes.array,
  canUpload: PropTypes.bool
}

export default VaultList
